import React from "react";
import { Link } from "gatsby";

import { useStyletron } from "baseui";
import { StyledLink } from "baseui/link";
import { Paragraph1, Label1 } from "baseui/typography";
import { Button, KIND } from "baseui/button";

import Page from "../../../components/internal-page";
import BackButton from "../../../components/back-button";

import PROGRAMS from "../../../data/programs-frp.json";

const Program = () => {
  const program = PROGRAMS.data[0].programs.data[1];
  const {
    name,
    description,
    interestRate,
    loanTerm,
    minAmount,
    maxAmount,
    projectBudget,
    okved,
  } = program;

  const okverdList = okved.allow.reduce((acc, val) => (acc += `${val}, `), "");
  const bannedOkverdList = okved?.deny.reduce(
    (acc, val) => (acc += `${val}, `),
    ""
  );
  const [css, theme] = useStyletron();
  const space = css({ marginLeft: theme.sizing.scale800 });

  return (
    <Page
      title={`Программа «${name}»`}
      description={`Региональная программа Фонда развития промышленности - «${name}»`}
      parent="frp"
      path="/frp/programs"
    >
      <Label1>
        от {minAmount.toLocaleString("ru-RU")}&nbsp;&#8381; до{" "}
        {maxAmount.toLocaleString("ru-RU")}&nbsp;&#8381; для проектов с бюджетом
        от {projectBudget.toLocaleString("ru-RU")}
        &nbsp;&#8381;
      </Label1>
      <Paragraph1>
        Ставка: {interestRate}, Срок: до {loanTerm} мес.
      </Paragraph1>
      <Label1>Описание программы</Label1>
      <Paragraph1>{description}</Paragraph1>
      <Label1>Требования к заявителю</Label1>
      <Paragraph1>
        <ul>
          <li>
            Быть зарегистрированным и осуществлять деятельность на территории
            Архангельской области;
          </li>
          <li>
            Софинансирование собственных средств в размере не менее 20% от суммы
            Проекта;
          </li>
          <li>
            Отсутствие просроченной задолженности по уплате налогов, сборов, а
            также по заработной плате работникам и НДФЛ.
          </li>
        </ul>{" "}
      </Paragraph1>
      <Label1>Целевое использование</Label1>
      <Paragraph1>
        Приобретение нового оборудования в рамках Перечня технологического
        оборудования по обработке древесины, приобретение которого возможно в
        рамках программы "Проекты лесной промышленности".
      </Paragraph1>
      <Label1>Погашение займа</Label1>
      <Paragraph1>
        <ul>
          <li>
            Проценты по займу уплачиваются ежеквартально, начиная с первого
            квартала после выдачи займа;
          </li>
          <li>
            {" "}
            Погашение основной суммы займа производится ежеквартально равными
            долями начиная с первого квартала после выдачи займа, возможно
            предоставление льготного периода длительностью до 12 месяцев.
          </li>
        </ul>
      </Paragraph1>
      <Label1>Документы</Label1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1OKNdlbVs8_Bp9C8tBfjLDKUM5eDWcMoR/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          {`Стандарт Фонда по программе «${name}»`}
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1FX4eZ2gerWrXya6msXCVgRJc5n0kMXH-/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Дополнительные условия предоставления займов в 2022 году
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1uknZ7nwv2OM2ofWq69jIRQ9bWFuCtQIE/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          {`Перечень технологического оборудования по программе «${name}»`}
        </StyledLink>
      </Paragraph1>
      <div
        className={css({
          display: "flex",
          alignContent: "center",
        })}
      >
        <BackButton title="Назад" />
        <span className={space} />
        <Button
          $as={Link}
          to="/frp/apply"
          state={{ id: "2" }}
          kind={KIND.primary}
        >
          Подать заявку
        </Button>
      </div>
    </Page>
  );
};

export default Program;
